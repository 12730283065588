import React from "react";
import Loadable from "react-loadable";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AppConfig } from "./AppConfig";

export interface ParentAppProps {
  config: AppConfig;
}

declare global {
  interface Window {
    dataLayer: Array<unknown>;

    ga: Function;

    // eslint-disable-next-line camelcase
    gtag_report_conversion: (url?: string) => boolean;

    // eslint-disable-next-line camelcase
    bing_report_conversion: () => boolean;

    CallTrk: {
      swap: () => null;
    };

    Trustpilot: {
      loadFromElement: any;
    };
  }
}
if (typeof window !== "undefined") {
  window.dataLayer = window.dataLayer || {};
}

const LoadableSurveyApp = Loadable({
  loader: () => import(/* webpackChunkName: "start" */ "./survey/SurveyApp"),
  loading: () => null,
  modules: ["./survey/SurveyApp"],
  webpack: () => [require.resolveWeak("./survey/SurveyApp")],
});
const LoadablePortalApp = Loadable({
  loader: () => import(/* webpackChunkName: "portal" */ "./portal/PortalApp"),
  loading: () => null,
  modules: ["./portal/PortalApp"],
  webpack: () => [require.resolveWeak("./portal/PortalApp")],
});

export default function ParentApp({ config }: ParentAppProps) {
  const location = useLocation();
  let redirectURL = `${config.appPath}/survey/landing/b`;

  if (location.search.length > 0) {
    redirectURL = `${redirectURL}${location.search}`;
  }

  return (
    <Switch>
      <Route
        path={`${config.appPath}/survey`}
        render={() => <LoadableSurveyApp config={config} />}
      />
      <Route
        path={`${config.portalAppPath}`}
        render={() => <LoadablePortalApp config={config} />}
      />
      <Route path="*">
        <Redirect to={redirectURL} />
      </Route>
    </Switch>
  );
}
