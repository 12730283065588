/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";
import { render, hydrate } from "react-dom";
import Loadable from "react-loadable";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs, LogsInitConfiguration } from "@datadog/browser-logs";
import ParentApp, { ParentAppProps } from "./ParentApp";
import { AppConfig } from "./AppConfig";

const Application = ({ config }: ParentAppProps) => (
  <BrowserRouter>
    <ParentApp config={config} />
  </BrowserRouter>
);

const root = document.querySelector("#root") as Element;
const appConfig: AppConfig = JSON.parse(
  atob(root.getAttribute("data-app-config") || "")
);

if (typeof window !== "undefined" && appConfig.datadogClientToken !== "") {
  const datadogConfig: LogsInitConfiguration = {
    clientToken: appConfig.datadogClientToken,
    env: appConfig.appEnv,
    service: "ssdi-intake-flow",
    version: process.env.APP_VERSION || "unknown",
    forwardErrorsToLogs: true,
  };
  datadogLogs.init(datadogConfig);

  datadogRum.init({
    // This is the same for all envs, no need to make it configurable
    applicationId: "dc2b4b4a-d305-4231-aad4-a1d8dde828da",
    clientToken: appConfig.datadogClientToken,
    site: "datadoghq.com",
    service: "ssdi-intake",
    env: appConfig.appEnv,
    // Specify a version number to identify the deployed version of your application in Datadog
    // TODO: figure out how to get commit hash or some sort of version identifier
    // version: '1.0.0',
    // Record ALL sessions
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackFrustrations: true,
    telemetrySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    silentMultipleInit: true,
    // Allow tracking of all user inputed text
    defaultPrivacyLevel: "allow",
    enableExperimentalFeatures: ["clickmap"],
  });

  datadogRum.startSessionReplayRecording();
}

if (root?.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(<Application config={appConfig} />, root);
  });
} else {
  // If we're not running on the server, just render like normal
  render(<Application config={appConfig} />, root);
}
